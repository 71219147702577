<template>
  <div
    :class="`tech-req__menu-item ${disabled ? 'tech-req__menu-item_disabled' : ''} ${
      active ? 'tech-req__menu-item_active' : ''
    }`"
    @click="onHandleClick">
    {{ `${index}. ${value[language]}` }}
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    id: Number,
    index: Number,
    value: Object,
    disabled: Boolean,
    active: Boolean,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    onHandleClick() {
      if (!this.disabled) {
        this.$emit('click', this.id);
      }
    },
  },
};
</script>
